import { useCmsContents } from '@bluheadless/ui-logic/src/hooks/cms/useCmsContents'
import { useTheme } from '@mui/system'
import { Grid } from '@mui/material'
import Typography from '@bluheadless/ui/src/atoms/typography'
import CardImage from '@bluheadless/ui/src/molecules/card/card-image'
import SkeletonCard from '@bluheadless/ui/src/molecules/skeleton/card/skeleton-card'
import Carousel from '@bluheadless/ui/src/organisms/carousel'
import { Slide } from '@bluheadless/ui/src/particles/slider/slider'
import { Root } from './slider-magazine.styled'
import { useIntl } from 'react-intl'

const MasterDataCollectionSliderMagazine = ({
	masterContentType,
	masterContentLimit,
	masterContentSort,
	masterContentSortOrder,
	// eslint-disable-next-line no-unused-vars
	masterContentTemplate,
	...props
}) => {
	const { contents, loading } = useCmsContents({
		type: masterContentType,
		limit: masterContentLimit,
		order: [`metadata.${masterContentSort} ${masterContentSortOrder}`],
	})

	const theme = useTheme()
	const { formatMessage } = useIntl()

	const sliderConfig = {
		controlsPosition: 'default',
		slidesPerView: 1.2,
		pagination: true,
		navigation: false,
		spaceBetween: 9,
		slidesPerGroup: 1,
		breakpoints: {
			[theme.breakpoints.values.xsm]: {
				slidesPerView: 1.2,
				spaceBetween: 9,
			},
			[theme.breakpoints.values.sm]: {
				slidesPerView: 3,
				spaceBetween: 9,
			},
			[theme.breakpoints.values.md]: {
				slidesPerView: 3,
				spaceBetween: 20,
			},
			[theme.breakpoints.values.lg]: {
				slidesPerView: 4,
				spaceBetween: 20,
			},
		},
	}

	const skeletonProps = {
		orientation: 'vertical',
		rows: 1,
		textRowsNumber: 3,
		responsiveProps: {
			xs: {
				columns: 2,
				cardRatio: 0.8,
			},
			sm: {
				columns: 3,
				cardRatio: 0.8,
				rowGap: 1,
				columnGap: 1,
			},
			md: {
				columns: 3,
				cardRatio: 0.8,
			},
			lg: {
				columns: 4,
				cardRatio: 0.8,
			},
			xl: {
				columns: 4,
				cardRatio: 0.8,
			},
		},
	}

	return (
		<Root {...props}>
			{loading && !contents?.length > 0 && <SkeletonCard {...skeletonProps} />}
			{contents?.length > 0 && (
				<Carousel {...sliderConfig}>
					{contents.map(({ title, urlKey, metadata }, index) => (
						<Slide key={index}>
							<CardImage
								image={{
									src: metadata.blog_image
										? '/contentmanager/content/' + metadata.blog_image
										: metadata.blog_image_src || undefined,
									originalWidth: parseFloat(metadata.blog_image_width),
									originalHeight: parseFloat(metadata.blog_image_height),
								}}
								title={title}
								titleProps={{ component: 'h5' }}
								subtitle={metadata.blog_subtitle}
								subtitleProps={{ component: 'p' }}
								description={
									<Grid container spacing={1}>
										<Grid item xs={12} key={index}>
											<Typography>{metadata.blog_content}</Typography>
										</Grid>
									</Grid>
								}
								descriptionProps={{ component: 'div' }}
								cta1={formatMessage({ id: 'read_more' })}
								cta1Props={{ href: urlKey }}
							/>
						</Slide>
					))}
				</Carousel>
			)}
		</Root>
	)
}

MasterDataCollectionSliderMagazine.defaultProps = {}

MasterDataCollectionSliderMagazine.propTypes = {}

export default MasterDataCollectionSliderMagazine
